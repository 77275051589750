import React, { useEffect, useRef } from "react";
import gsap from "gsap";
import "./InfiniteScrollText.css"; // Import the CSS file for styling

const skills = [
  "JAVASCRIPT",
  "REACT",
  "VUEJS",
  "HTML",
  "CSS",
  "DART",
  "JAVA",
  "FIREBASE",
  "AWS",
  "SQL",
  "MICROSERVICES",
  "APIs",
  "MACHINE LEARNING",
  "TENSORFLOW",
  "GITHUB COPILOT",
];

const InfiniteScrollText = () => {
  const scrollContentRef = useRef(null);
  const animationRef = useRef(null);

  useEffect(() => {
    const scrollContent = scrollContentRef.current;

    // Generate the text content with arrow images between items
    const textContent = skills
      .map(skill => `<span class="skill-item">${skill}</span>`)
      .join(`<img src="/images/arrow.png" class="arrow-icon-skills" alt="arrow" />`);

    // Apply text content to the scroll element
    scrollContent.innerHTML = `
      <div class="scroll-text">${textContent}</div>
      <div class="scroll-text">${textContent}</div>
    `;

    // Get the width of one set of text
    const textWidth = scrollContent.querySelector('.scroll-text').offsetWidth;

    // GSAP animation from right to left
    animationRef.current = gsap.fromTo(
      scrollContent,
      { x: 0 },
      { x: -textWidth, duration: 22, ease: "linear", repeat: -1 }
    );

    
  }, []);

  return (
    <div className="scroll-container">
      <div className="scroll-content" ref={scrollContentRef}></div>
    </div>
  );
};

export default InfiniteScrollText;
