import React, { useEffect } from "react";
import gsap from "gsap";
import Draggable from "gsap/Draggable";
import "./MusicPage.css";

function MusicPage() {
  useEffect(() => {
    gsap.registerPlugin(Draggable);

    const cards = document.querySelectorAll(".image-link-container");

    // Initialize GSAP Draggable on each card
    Draggable.create(cards, {
      type: "x,y", // Enable dragging on both x and y axis
      bounds: ".music-page", // Keep the dragging within the .music-page container
      inertia: true, // Adds some natural momentum to the dragging
    });
  }, []);

  return (
    <div className="music-page">
      <div
        className="about-me-text-container"
        data-scroll
        data-scroll-speed="1.2"
      >
        <p className="small-heading">MUSIC</p>
        <div className="music-page-container">

        <div className="center-image-wrapper">
            <img
              src="/images/bitmoji7.png"
              alt="Bitmoji"
              className="bitmoji-center"
              data-scroll
              data-scroll-speed="1.4"
            />
          </div>
          <div className="all-music-images">
          <div className="column-wrapper-left">
            <div className="image-link-container">
              <img
                src="/images/music1.png"
                alt="Music 1"
                className="music-image-left"
              />
              <div className="overlay">
                <div className="icon-container">
                  <a
                    href="https://open.spotify.com/artist/0nVMi9CGb8l9BsR6uMkNW6?si=tCo_VsP9SSCVg5d4rpJrgw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/spotify.png"
                      alt="Spotify"
                      className="icon spotify-icon"
                    />
                  </a>
                  <a
                    href="https://music.apple.com/us/artist/superboy/1751284429"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/apple.png"
                      alt="Apple Music"
                      className="icon apple-icon"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="image-link-container">
              <img
                src="/images/music2.png"
                alt="Music 2"
                className="music-image-left"
              />
              <div className="overlay">
                <div className="icon-container">
                  <a
                    href="https://open.spotify.com/artist/0nVMi9CGb8l9BsR6uMkNW6?si=tCo_VsP9SSCVg5d4rpJrgw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/spotify.png"
                      alt="Spotify"
                      className="icon spotify-icon"
                    />
                  </a>
                  <a
                    href="https://music.apple.com/us/artist/superboy/1751284429"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/apple.png"
                      alt="Apple Music"
                      className="icon apple-icon"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
          
          <div className="column-wrapper-right">
            <div className="image-link-container">
              <img
                src="/images/music3.png"
                alt="Music 3"
                className="music-image-right"
              />
              <div className="overlay">
                <div className="icon-container">
                  <a
                    href="https://open.spotify.com/artist/0nVMi9CGb8l9BsR6uMkNW6?si=tCo_VsP9SSCVg5d4rpJrgw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/spotify.png"
                      alt="Spotify"
                      className="icon spotify-icon"
                    />
                  </a>
                  <a
                    href="https://music.apple.com/us/artist/superboy/1751284429"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/apple.png"
                      alt="Apple Music"
                      className="icon apple-icon"
                    />
                  </a>
                </div>
              </div>
            </div>
            <div className="image-link-container">
              <img
                src="/images/music4.png"
                alt="Music 4"
                className="music-image-right"
              />
              <div className="overlay">
                <div className="icon-container">
                  <a
                    href="https://open.spotify.com/artist/0nVMi9CGb8l9BsR6uMkNW6?si=tCo_VsP9SSCVg5d4rpJrgw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/spotify.png"
                      alt="Spotify"
                      className="icon spotify-icon"
                    />
                  </a>
                  <a
                    href="https://music.apple.com/us/artist/superboy/1751284429"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="/images/apple.png"
                      alt="Apple Music"
                      className="icon apple-icon"
                    />
                  </a>
                </div>
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MusicPage;
