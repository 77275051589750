import React, { useEffect, useState } from "react";
import "./Loader.css"; // Ensure to add your styles here

const Loader = () => {
  const [progress, setProgress] = useState(0);
  const [loadingComplete, setLoadingComplete] = useState(false);

  useEffect(() => {
    // Simulate progress increment
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setLoadingComplete(true);
          return 100;
        }
        return prev + 1;
      });
    }, 30); // Increment every 30ms

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, []);

  // If loading is complete, add a delay before removing loader
  useEffect(() => {
    if (loadingComplete) {
      const timer = setTimeout(() => {
        // Handle redirection or next steps here
      }, 2000); // Delay to show the loader fully before disappearing

      return () => clearTimeout(timer); // Cleanup timer on unmount
    }
  }, [loadingComplete]);

  return (
    <div className={`loader ${loadingComplete ? "fade-out" : ""}`}>
      <div className="slider-container">
        <div className="slider-image"></div>
      </div>
      <div className="loading-bar">
        <div className="progress-bar" style={{ width: `${progress}%` }}></div>
      </div>
      <div className="loading-text">
        <p>Loading... {progress}%</p>
      </div>
    </div>
  );
};

export default Loader;
